import React from 'react';

const Circle = ({ size = 80, borderColor = 'black', borderWidth = 8 }) => {
    const circleStyle = {
        width: size,
        height: size,
        borderRadius: '50%',
        border: `${borderWidth}px solid ${borderColor}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return <div style={circleStyle}></div>;
};

export default Circle;