import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import ReactTooltip from 'react-tooltip';

import { Config, ConfigEnum } from 'util/Config';
import ProjectDao from "data/ProjectDao.js";
import QuotationDao from "data/QuotationDao.js";
import moment from 'moment';
import { Block } from 'notiflix';
import classnames from 'classnames';
import CompleteProjectModel from './CompleteProjectModal';

let _PRIORITY_OPTIONS = [
    { label: 'Low', value: 0 },
    { label: 'Medium', value: 1 },
    { label: 'High', value: 2 },
    { label: 'Urgent', value: 3 },
];

let _STATUS_OPTIONS = [
    { label: 'Pending', value: 0 },
    { label: 'In Progress', value: 1 },
    { label: 'Completed', value: 2 },
    { label: 'All Tasks Complete', value: 3 },
];

/// <summary>
/// Author: Christopher Chan
/// </summary>
const ProjectModal = forwardRef(({ toggle, project, getProjectList }, ref) => {
    const { register, handleSubmit, control, errors, reset, watch, setValue } = useForm();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (project) {
            let defaultValues = {
                typeOfLift: project.typeOfLift,
                startDate: new Date(project.startDate),
                endDate: new Date(project.endDate),
                handoverDate: project.handoverDate !== null ? new Date(project.handoverDate) : null,
                status: String(project.status),
                priority: parseInt(project.priority),
            }

            reset(defaultValues);
        }
    }, [project]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = async (data) => {
        let newData = {
            ...data,
            sneId: project.sneId,
            startDate: moment(data.startDate).format('YYYY-MM-DD'),
            endDate: moment(data.endDate).format('YYYY-MM-DD'),
        }

        if (data.handoverDate !== null) {
            newData = {
                ...newData,
                handoverDate: moment(data.handoverDate).format('YYYY-MM-DD'),
            }
        }

        let dao = new ProjectDao();

        await dao.updateProject(newData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.success('Success', project ? "Project Updated Successful" : "Project Created Successful");
                getProjectList();
                toggle();
            } else {
                Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col><strong>Project Details</strong></Col>
            </Row>
            <Row>
                <Col>
                    <InputHoc
                        name='typeOfLift'
                        label='Type of Lift'
                        inputType={InputTypes.SELECT}
                        control={control}
                        options={SelectField.TYPE_OF_LIFT_OPTIONS}
                        error={errors?.typeOfLift}
                        rules={{ required: 'required' }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputHoc
                        name="status"
                        label="Status"
                        inputType={InputTypes.SELECT}
                        control={control}
                        options={_STATUS_OPTIONS}
                    />
                </Col>
                <Col>
                    <InputHoc
                        name="priority"
                        label="Priority"
                        inputType={InputTypes.SELECT}
                        control={control}
                        options={_PRIORITY_OPTIONS}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputHoc
                        label='Start Date'
                        name='startDate'
                        inputType={InputTypes.DATEPICKER}
                        control={control}
                        error={errors?.startDate?.message}
                    />
                </Col>
                <Col>
                    <InputHoc
                        label='End Date'
                        name='endDate'
                        inputType={InputTypes.DATEPICKER}
                        control={control}
                        error={errors?.endDate?.message}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputHoc
                        label='Handover Date'
                        name='handoverDate'
                        inputType={InputTypes.DATEPICKER}
                        control={control}
                        rules={{ required: false }}
                        error={errors?.handoverDate}
                    />
                </Col>
            </Row>
            <Row>
                <Col><InputHoc style={{ height: 'auto' }} label='Remark' name='remark' inputType={InputTypes.TEXTAREA} ref={register} error={errors?.remark?.message} /></Col>
            </Row>
            <button ref={ref} type="submit" style={{ display: 'none' }}>Save</button>
        </form>
    );
})

///<summary>
///Author: Sim
///</summary>
const Project = props => {
    const [modal, setModal] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [project, setProject] = useState(null);
    const [projectList, setProjectList] = useState([]);
    const [quotationListOption, setQuotationListOption] = useState([]);
    const [showCompleteModal, setShowCompleteModal] = useState(false);
    const [sneId, setSneId] = useState(null);
    const history = useHistory();
    const formRef = useRef();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const _COLUMN = useMemo(() => [
        {
            Header: "SNE",
            Cell: ({ row }) => {
                return <b>{row.original.sneId}</b>
            }
        },
        {
            Header: "Type of Lift",
            accessor: "typeOfLift"
        },
        {
            Header: "Title",
            accessor: "project.title"
        },
        {
            Header: "Description",
            accessor: "project.description"
        },
        {
            Header: "PIC",
            accessor: "project.pic.fullName",
            Cell: ({ row }) => {
                return row.original.project.pic.fullName + ` (${row.original.project.pic.employeeNumber})`;
            }
        },
        {
            Header: "Start Date",
            Cell: ({ row }) => {
                return moment(row.original.startDate).format('Do MMM YYYY, hh:mm A');
            }
        },
        {
            Header: "End Date",
            Cell: ({ row }) => {
                return moment(row.original.endDate).format('Do MMM YYYY, hh:mm A');
            }
        },
        {
            Header: "Priority",
            Cell: ({ row }) => {
                let priority = row.original.priority;
                let classes = classnames('label-priority',
                    {
                        'label-green': priority === 0,
                        'label-yellow': priority === 1,
                        'label-orange': priority === 2,
                        'label-red': priority === 3,
                    })

                return (
                    <div className={classes}>
                        {_PRIORITY_OPTIONS[priority].label}
                    </div>
                );
            }
        },
        {
            Header: "Status",
            Cell: ({ row }) => {
                let status = row.original.status;
                let classes = classnames('label custom-status-label', {
                    'label-secondary': status === 0,
                    'label-indigo': status === 1,
                    'label-success': status === 2,
                    'label-green': status === 3,
                })

                return (
                    <div className={classes}>
                        {row.original.statusName}
                    </div>
                );
            }
        },
        {
            Header: "Remark",
            accessor: "remark"
        },
        {
            Header: "Action",
            Cell: ({ row }) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div
                        data-for="edit-btn"
                        data-tip="Edit Project"
                        className="btn-round expand-theme btn-sm"
                        onClick={() => { toggle(); setProject(row.original) }}
                    >
                        <i class="fas fa-edit"></i>
                    </div>
                    {
                        row.original.project?.contract?.currentVersion?.signedDocument &&
                        <div
                            className="btn-round expand-theme btn-sm m-l-5"
                            data-for="tasks-btn"
                            data-tip="Project Tasks"
                            onClick={() => history.push(WebUrl._PROJECT_TASKS, row.original)}
                        >
                            <i class="fas fa-list-ol"></i>
                        </div>
                    }
                    {
                        row.original.status === 3 && <>
                            <div style={{ display: 'inline-block', height: '1.4rem', width: '1px', backgroundColor: '#CCC', marginLeft: '0.6rem', marginRight: '0.6rem' }}></div>
                            <div
                                className="btn-round expand-theme btn-sm"
                                data-for="complete-btn"
                                data-tip="Complete Tasks"
                                onClick={() => completeProjectHandler(row.original.id)}
                            >
                                <i class="fas fa-check-circle"></i>
                            </div>
                        </>
                    }
                    <ReactTooltip id="edit-btn" />
                    <ReactTooltip id="tasks-btn" />
                    <ReactTooltip id="complete-btn" />
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ])

    const getQuotationList = async () => {
        let dao = new QuotationDao();
        await dao.getQuotationList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var quotations = [];
                data.map((item) => {
                    if (item.signOff == 0) {
                        var quo = {
                            value: item.id,
                            label: item.quotationReferenceNumber
                        }
                        quotations.push(quo);
                    }
                });
                setQuotationListOption(quotations);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load quotations. Please try again later');
            }
        })
    }

    const getProjectList = async () => {
        Block.circle('#projectsTable');

        let dao = new ProjectDao();
        await dao.getProjectList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                console.log("project data", data);
                var projects = [];
                data.map((item) => {
                    projects.push({ ...item });
                });

                console.log(projects);
                setProjectList(projects);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load projects. Please try again later');
            }
        });

        Block.remove('#projectsTable');
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const completeProjectHandler = (id) => {
        setSneId(id);
        toggleCompleteModal();
    }

    const toggle = () => {
        setModal(!modal);
        setProject(null)
    };

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleCompleteModal = () => {
        setShowCompleteModal(!showCompleteModal);

        if (showCompleteModal) {
            setSneId(null);
        }
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const formHandler = () => {
        formRef.current.click();
    }

    /// <summary>
    /// Author: Sim
    /// </summary>
    useEffect(() => {
        getQuotationList();
        getProjectList();
    }, []);

    return (<>
        <div className=" float-xl-right">
            <button type="type" className="btn btn-sm btn-themed mb-2" onClick={() => history.push(WebUrl._PROJECT_CREATE)} ><i className="fa fa-plus mr-1"></i>Add Project</button>
        </div>
        <h1 className="page-header">Project Management</h1>
        <div id="projectsTable">
            <ReactTable columns={_COLUMN} data={projectList} />
        </div>
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>{project ? `Edit Project - ${project.sneId}` : "Add New Project"}</ModalHeader>
            <ModalBody>
                <ProjectModal
                    ref={formRef}
                    toggle={toggle}
                    project={project}
                    getProjectList={getProjectList}
                    quotationListOption={quotationListOption}
                />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-themed btn-min-width" onClick={formHandler}>Save</button>
            </ModalFooter>
        </Modal>
        {/* <CompleteProjectModel showModal={showCompleteModal} toggleModal={toggleCompleteModal} sneId={sneId} getProjectList={getProjectList} /> */}
    </>)
}

export default Project;
