import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { WebUrl } from 'util/Constant';
import useAuthController from 'recoil/useAuthController';
import { Config, ConfigEnum } from 'util/Config';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const DropdownProfile = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const _history = useHistory();
    const { logout } = useAuthController({
        logoutCallback: {
            success: () => {
                _history.push('/');
            },
            fail: () => { return },
        }
    });

    let configInstance = Config.getInstance();
    var user = configInstance.getValue(ConfigEnum._USER);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li">
            <DropdownToggle tag="a">
                <i class="las la-user-circle mr-1"></i>
                <span className="d-none d-md-inline mr-1">{user?.fullName}</span> <b className="caret"></b>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                {/* <Link to={WebUrl._PROFILE_MANAGEMENT}><DropdownItem>Edit Profile</DropdownItem></Link> */}               
                {/* Temporary push to login page, later will switch to recoil */}
                <DropdownItem onClick={() => { logout() }}>Log Out</DropdownItem>
            </DropdownMenu>
        </Dropdown >
    );
}

export default DropdownProfile;
