import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Input = forwardRef((props, ref) => {
    const {
        error,
        readOnly,
        ...rest
    } = props;
    const [hasError, setHasError] = useState();

    const inputClasses = classnames(
        'form-control-md',
        {
            'has-error': hasError,
            'form-control form-control-sm': !readOnly,
            'form-control-plaintext': readOnly
        }
    );

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setHasError(error);
    }, [error]);

    return <input ref={ref} {...rest} className={inputClasses} readOnly={readOnly} />;
});

Input.defaultProps = {
    placeholder: " "
}

export default Input;