import React, { useState, useEffect } from "react";
import { Card, CardBody, Collapse } from 'reactstrap';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ groupIndex, taskIndex, task, editTask, removeTask }) => {
    const PRIORITY = {
        0: 'Low',
        1: 'Medium',
        2: 'High',
        3: 'Urgent',
    };

    const STATUS = {
        0: 'Pending',
        1: 'In Progress',
        2: 'Completed',
    };

    let statusClasses = classnames(
        'label label-priority',
        {
            'label-secondary': task?.status === 0,
            'label-indigo': task?.status === 1,
            'label-success': task?.status === 2,
        },
    );

    let priorityClasses = classnames(
        'label-priority',
        {
            'label-green': task?.priority === 0,
            'label-yellow': task?.priority === 1,
            'label-orange': task?.priority === 2,
            'label-red': task?.priority === 3,
        },
    );

    return <>
        <div className="task-row">
            <i className="fas fa-level-up-alt"></i>
            <Card className="flex-fill task-card">
                <CardBody className="task-details-row p-t-10 p-b-10">
                    <div className="task-name">{task?.name}</div>
                    <div className="task-desc">{task?.description}</div>
                    <div className="task-duration">{task?.dueDate !== null ? moment(task?.dueDate).format('Do MMMM YYYY') : '-'}</div>
                    <div className="task-duration">{task?.assignees?.length} assignees</div>
                    <div className={statusClasses} style={{ marginRight: '10px' }}>{STATUS[task?.status]}</div>
                    <div className={priorityClasses}>{PRIORITY[task?.priority]}</div>
                </CardBody>
            </Card>
            <button type="button" className="btn expand-theme btn-round ml-3" onClick={() => editTask(groupIndex, taskIndex)} data-tip="Edit Task" data-for={`generalTooltip${taskIndex}`}>
                <i class="far fa-edit"></i>
            </button>
            <button type="button" className="btn expand-theme btn-round ml-2 test-button" onClick={() => removeTask(task?.id)} data-tip="Not Applicable" data-for={`generalTooltip${taskIndex}`} disabled={task?.status !== 0}>
                <i class="fas fa-times"></i>
            </button>
            <ReactTooltip id={`generalTooltip${taskIndex}`} effect='float' type="dark" />
        </div>
    </>
}
