import React, { useMemo, useEffect, useRef, useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import ReactTable from 'components/react-table/ReactTable';
import { ApiKey, Status } from 'util/Constant';
import { useSetRecoilState } from 'recoil';
import { alertNotification } from 'recoil/Atoms';
import { WebUrl } from 'util/Constant';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomInput from "components/form/CustomInput";
import { InputTypes } from "util/Constant";
import InputHoc from "components/form/InputHoc";
import { useForm } from "react-hook-form";
import CustomDatePicker from "components/form/CustomDatePicker";
import { Datepicker } from "components/form";
import classnames from 'classnames';
import moment from 'moment';
import Circle from "components/form/Circle";

const Dashboard = props => {
    const setAlarmNotify = useSetRecoilState(alertNotification);
    const history = useHistory();
    const chartDivRef = useRef();
    const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
      } = useForm({});

      let _PRIORITY_OPTIONS = [
        { label: 'Low', value: 0 },
        { label: 'Medium', value: 1 },
        { label: 'High', value: 2 },
        { label: 'Urgent', value: 3 },
    ];
    
    let _STATUS_OPTIONS = [
        { label: 'Pending', value: 0 },
        { label: 'In Progress', value: 1 },
        { label: 'Completed', value: 2 },
        { label: 'All Tasks Complete', value: 3 },
    ];
    
    useEffect(() => {
        if (chartDivRef.current) {
            setChartSize({ width: chartDivRef.current.offsetWidth, height: chartDivRef.current.offsetHeight });
            console.log(chartDivRef.current.offsetWidth)
        }
        // console.log(chartDivRef.current);
    }, [])

    const _COLUMN = useMemo(() => [
        {
            Header: "SNE",
            Cell: ({ row }) => {
                return <b>{row.original.sneId}</b>
            }
        },
        {
            Header: "Type of Lift",
            accessor: "typeOfLift"
        },
        {
            Header: "Title",
            accessor: "project.title"
        },
        {
            Header: "Description",
            accessor: "project.description"
        },
        {
            Header: "PIC",
            accessor: "project.pic.fullName",
            Cell: ({ row }) => {
                return row.original.project.pic.fullName + ` (${row.original.project.pic.employeeNumber})`;
            }
        },
        {
            Header: "Start Date",
            Cell: ({ row }) => {
                return moment(row.original.startDate).format('Do MMM YYYY, hh:mm A');
            }
        },
        {
            Header: "End Date",
            Cell: ({ row }) => {
                return moment(row.original.endDate).format('Do MMM YYYY, hh:mm A');
            }
        },
        {
            Header: "Priority",
            Cell: ({ row }) => {
                let priority = row.original.priority;
                let classes = classnames('label-priority',
                    {
                        'label-green': priority === 0,
                        'label-yellow': priority === 1,
                        'label-orange': priority === 2,
                        'label-red': priority === 3,
                    })

                return (
                    <div className={classes}>
                        {_PRIORITY_OPTIONS[priority].label}
                    </div>
                );
            }
        },
        {
            Header: "Status",
            Cell: ({ row }) => {
                let status = row.original.status;
                let classes = classnames('label custom-status-label', {
                    'label-secondary': status === 0,
                    'label-indigo': status === 1,
                    'label-success': status === 2,
                    'label-green': status === 3,
                })

                return (
                    <div className={classes}>
                        {row.original.statusName}
                    </div>
                );
            }
        },
        {
            Header: "Remark",
            accessor: "remark"
        }        
    ])

    const _NEXT_DELIVERY = useMemo(() => [
        {
            Header: "Delivery Code",
            Cell: ({ row }) => {
                return <b>{row.original.sneId}</b>
            }
        },
        {
            Header: "Delivery Date",
            accessor: "deliveryDate"
        },
        {
            Header: "Status",
            Cell: ({ row }) => {
                let status = row.original.status;
                let classes = classnames('label custom-status-label', {
                    'label-secondary': status === 0,
                    'label-indigo': status === 1,
                    'label-success': status === 2,
                    'label-green': status === 3,
                })

                return (
                    <div className={classes}>
                        {row.original.statusName}
                    </div>
                );
            }
        }        
    ])

    const _JKKP_STATUS = useMemo(() => [
        {
            Header: "SNE",
            Cell: ({ row }) => {
                return <b>{row.original.sneId}</b>
            }
        },
        {
            Header: "Product Model",
            accessor: "productModel"
        },
        {
            Header: "JKKP Status",
            Cell: ({ row }) => {
                let status = row.original.status;
                let classes = classnames('label custom-status-label', {
                    'label-secondary': status === 0,
                    'label-indigo': status === 1,
                    'label-success': status === 2,
                    'label-green': status === 3,
                })

                return (
                    <div className={classes}>
                        {row.original.statusName}
                    </div>
                );
            }
        }        
    ])

    return <>
        <Row>
            <Col>
                <h1 className="page-header">Dashboard</h1>
            </Col>
        </Row>
       
        <Row>
            <Col>
                <h1>Hello Alice</h1>
            </Col>
            <Col>
                {/* <CustomDateRangePicker
                  control={control}
                  {...register("title", {
                    required: "title is required",
                 })}
                  name="dob"
                  label="Birthday"
                /> */}
            </Col>                                                              
        </Row>
        <br/>
        <Row>
            <Col>
                <h2>Project Status</h2>
            </Col>            
        </Row>
        <Row xs="auto">   
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#0099FF"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>In Progress</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-blue">35%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>      
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#00C242"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Completed</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-green">25%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>      
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#EA0000"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Overdue</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-red">15%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>      
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#FF8616"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Extended</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-orange">25%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>            
        </Row>
        <br/>
        <Row>
            <Col>
               <ReactTable subHeader={"Recent Concluded Projects"} columns={_COLUMN} showFilter={false} />
            </Col>
        </Row>
        <br/>
        <Row>
            <Col md="6">
               <ReactTable subHeader={"Next Delivery"} columns={_NEXT_DELIVERY} showFilter={false} />
            </Col>
            <Col md="6">
               <ReactTable subHeader={"JKKP Status"} columns={_JKKP_STATUS} showFilter={false} />
            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
                <h2>Payment Status</h2>
            </Col>            
        </Row>
        <Row xs="auto">   
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#0099FF"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Claim Pending</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-blue">35%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>      
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#00C242"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Claim Submitted</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-green">25%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>      
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#0099FF"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Payment pending</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-blue">15%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>      
            <Col md="3">
                <Card className="factsheet-card">
                    <CardBody>
                        <div className="dashboard-item">
                            <div className="item-title blue">
                            <div class="row">
                                <div class="col-md-4">
                                    <Circle
                                        borderColor="#00C242"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <h4>Payment Submitted</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="title-green">25%</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>                            
                    </CardBody>
                </Card> 
            </Col>            
        </Row>
        <br/>
        
            <Row>
                <Col md="6">
                    <Row>
                        <Col>
                            <h2>Product Type</h2>
                        </Col>            
                    </Row>
                    <Row>                    
                        <Col md="3">
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Home Lifts</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                        <Col md="3">
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>OKU Lifts</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                        <Col md="3">
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Dumbwaiters</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                        <Col md="3">
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Gooda Hoist</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                        <Col md="3">
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Car Showroom Lift</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                        <Col md="3">
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Scissor Lift</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                    </Row>
                </Col>
                <Col md="6">
                    <Row>
                        <Col>
                            <h2>JKKP Required</h2>
                        </Col>                    
                    </Row>
                    <Row>
                        <Col>
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Required</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                        <Col>
                            <Card className="factsheet-card">
                                <CardBody>
                                    <div class="col-md-12">
                                        <h4>Not Required</h4>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <label class="title-blue">35 Units</label>
                                            </div>
                                        </div>
                                    </div>                     
                                </CardBody>
                            </Card> 
                        </Col>
                    </Row>
            </Col>
            </Row>        
    </>;
}

export default Dashboard;
