import { stringIsNullOrEmpty } from 'util/Utility';
import { ApiKey, HostKey } from "util/Constant";

const _API_URL = `${HostKey.getApiHost()}/api`;

/// <summary>
/// Author: Christopher Chan
/// </summary>
class DataAccessObject {
    _signal = null;

    async get(url, token = null) {
        let headers = {
            "Accept": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return fetch(`${_API_URL}${url}`, {
            method: ApiKey._API_GET,
            headers: headers,
            signal: this._signal
        })
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                throw error;
            });
    }

    async post(url, data = null, token = null) {
        let headers = {
            "Content-Type": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let body;
        if (data !== null) {
            body = JSON.stringify(data);
        }

        let options = {
            method: ApiKey._API_POST,
            headers: headers,
            body: body,
            signal: this._signal
        }

        return this.fetchPost(`${_API_URL}${url}`, options).then((response) => { return response.json() });
    }

    async postForm(url, data = null, token = null) {
        let headers = {
            'Accept': ApiKey._API_APPLICATION_JSON,
            // "Content-Type": ApiKey._API_FORM_DATA,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        let body = data;

        let options = {
            method: ApiKey._API_POST,
            // mode: "no-cors",
            headers: headers,
            body: body,
            signal: this._signal,
        }

        return this.fetchPost(`${_API_URL}${url}`, options).then((response) => { return response.json() });
    }

    async download(url, token = null) {
        let headers = {
            "Accept": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        };

        if (!stringIsNullOrEmpty(token)) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return fetch(`${_API_URL}${url}`, {
            method: ApiKey._API_GET,
            headers: headers,
            signal: this._signal
        })
            .then((response) => {
                return response.arrayBuffer();
            })
            .catch((error) => {
                throw error;
            });
    }

    async fetchPost(url, options = {}) {
        if (!options.method) {
            options.method = ApiKey._API_POST
        }

        if (!options.signal) {
            options.signal = this._signal;
        }

        return fetch(url, options)
            .catch((error) => {
                throw error;
            });
    }

    setSignal(signal) {
        this._signal = signal;
    }


    async delete(url = null) {
        let headers = {
            "Accept": ApiKey._API_APPLICATION_JSON,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
        };

        return fetch(url, {
            method: ApiKey._API_DELETE,
            headers: headers,
            signal: this._signal
        })
            .then((response) => {
                console.log("SUccess");
                return response.json();
            })
            .catch((error) => {
                throw error;
            });
    }

}

export default DataAccessObject;
