const _DEV_HOST = "http://localhost:8000";
const _SIT_HOST = "http://nicheapi.gzit.com.my";
const _PROD_HOST = "https://api.niche123.net";

/// <summary>
/// Author : Robin
/// Host for different Environment
/// </summary>
export class HostKey {
    static getApiHost = () => {
        switch (process.env.REACT_APP_ENV) {
            case "dev":
                return _DEV_HOST;
    
            case "sit":
                return _SIT_HOST;
    
            case "prod":
                return _PROD_HOST;
    
            default:
                return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? _DEV_HOST : _SIT_HOST;
        }
    }
}

export const _IMAGE_URL = `${HostKey.getApiHost()}/storage`;

/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
    static _API_SUCCESS_KEY = "success";
    static _API_MESSAGE_KEY = "message";
    static _API_DATA_KEY = "data";

    static _API_CONTENT_TYPE = 'Content-Type';
    static _API_ACCEPT = "Accept";
    static _API_APPLICATION_JSON = "application/json";
    static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
    static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
    static _API_FORM_DATA = "multipart/form-data";
    static _API_POST = "POST";
    static _API_GET = "GET";
    static _API_DELETE = "DELETE";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
    //neps 
    static _API_LOGIN = "/auth/login";
    static _API_VALIDATE_LOGIN = "/auth/user";
    static _API_LOGOUT = '/auth/logout';
    static _API_CONTRACT_LIST = "/contract";
    static _API_CREATE_CONTRACT = "/contract";
    static _API_EMPTY_CONTRACT_QUOTATION_LIST = "/quotation/emptyContract";
    static _API_UPLOAD_SIGNED_DOCUMENT = '/contract/update/status';
    static _API_UPDATE_CONTRACT = '/projectContract/update';
    static _API_GET_CONTRACT_SITES = "/projectContract/sites";
    static _API_GET_SITE_CONTRACTS = "/projectContract/site/contracts";
    static _API_QUOTATION_LIST = '/quotation/list';
    static _API_QUOTATION_ITEMS = '/quotation/items';
    static _API_GET_PROJECT = "/project/list";
    static _API_CREATE_PROJECT = '/project/create';
    static _API_UPDATE_PROJECT = '/project/update';
    static _API_GET_PROJECT_TASKS_BY_ID = '/project/task/list';
    static _API_REMOVE_PROJECT_TASK = '/project/task/delete';
    static _API_UPDATE_TASK = '/project/task/update';
    static _API_GET_USER = '/user/list';
    static _API_SERVICE_AGENT_LIST = '/service-agent/list';
    static _API_PROJECT_COMPLETE = '/project/complete';
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
    //neps
    static _LOGIN = "/login";
    static _DASHBOARD = "/dashboard";
    static _CONTRACT = "/contract";
    static _PROJECT = "/project";
    static _PROJECT_TASKS = "/project/tasks";
    static _PROJECT_CREATE = "/project/create";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
    static _LANGUAGE = "language";
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
    static _ENGLISH_GB = "en-GB";
    static _CHINESE_HK = "zh-HK";
    static _CHINESE_CN = "zh-CN";
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class Status {
    static _DELETED = -2;
    static _REJECTED = -1;
    static _INACTIVE = 0;
    static _ACTIVE = 1;
    static _PENDING = 2;
    static _EXPIRED = 3;
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const BtnTypes = {
    ADD: 'add',
    DELETE: 'delete',
    VIEW: 'view',
    EDIT: 'edit',
    MISC: 'misc',
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const InputTypes = {
    INPUT: 'input',
    SELECT: 'select',
    RADIO: 'radio',
    PHONE: 'phone',
    TEXTAREA: 'textarea',
    ATTACHMENT: 'attachment',
    DATEPICKER: 'datepicker',
    CHECKBOX: 'checkbox',
    FILEUPLOAD: 'fileupload',
    IMAGEUPLOADER: 'imageuploader'
}

/// <summary>
/// Author: Lewis
/// </summary>
export const SelectField = {
    STATUS_OPTIONS: [
        { label: 'Pending', value: 0 },
        { label: 'In Progress', value: 1 },
        { label: 'Completed', value: 2 }
    ],
    TYPE_OF_SERVICE_OPTIONS: [
        { label: 'Preventive Service', value: 'Preventive Service' },
        { label: 'Inspection Service', value: 'Inspection Service' },
    ],
    TYPE_OF_LIFT_OPTIONS: [
        { label: 'Passenger', value: 'Passenger' },
        { label: 'Goods', value: 'Goods' },
        { label: 'Hydraulic', value: 'Hydraulic' },
        { label: 'MRL', value: 'MRL' },
    ]
}

export const SidebarType = {
    _ACCOUTANT: 1,
    _ADMIN: 2
}

/// <summary>
/// Author: Christopher Chan
/// </summary>
export const Colors = {
    THEME: '#5E96AE',
    OFF_THEME: '#eff6f3',
    WHITE: '#FFF',
    RED: '#f64e60',
    GRAY: '#dfe1e4',
}
