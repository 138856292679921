import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link } from 'react-router-dom';
import { InputTypes, SelectField, WebUrl, ApiKey, _IMAGE_URL } from "util/Constant";
import InputHoc from "components/form/InputHoc.js";
import { useForm, useFieldArray } from "react-hook-form";
import { Config, ConfigEnum } from 'util/Config';
import ReactTooltip from 'react-tooltip';

import ProjectDao from "data/ProjectDao.js";
import ContractDao from "data/ContractDao.js";
import QuotationDao from "data/QuotationDao.js";
import { stringIsNullOrEmpty } from 'util/Utility.js';
import { useRecoilState, useSetRecoilState } from "recoil";
import { appState } from "recoil/Atoms.js";
import moment from 'moment';
import { Block } from 'notiflix';

const NewContract = forwardRef(({ setAppState, toggleNewContractModal, emptyContractQuotationList, getContractList, getEmptyContractQuotation }, ref) => {
    const { register, handleSubmit, control } = useForm({});

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const onSubmit = async (data) => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));

        let dao = new ContractDao();
        await dao.createNewContract(data, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', 'Project Contract Created');
                getContractList();
                getEmptyContractQuotation();
                toggleNewContractModal();
            } else {
                Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                <div className="mt-2 mb-2">
                    <Row>
                        <Col><InputHoc name="id" label="Quotation ID" inputType={InputTypes.SELECT} options={emptyContractQuotationList} control={control} rules={{ required: 'Quotation ID is required.' }} /></Col>
                    </Row>
                </div>
            }
            <button type="submit" className="hide" ref={ref}></button>
        </form>
    );
});

const UploadContract = forwardRef(({ setAppState, toggleUploadModal, contract, getContractList }, ref) => {
    const { register, handleSubmit, control } = useForm({});

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const onSubmit = async (data) => {
        setAppState(prevState => ({ ...prevState, isBusy: true }));

        const formData = new FormData();

        formData.append("id", contract.id);
        formData.append("signedDocument", data.signedDocument[0]);

        let dao = new ContractDao();
        await dao.uploadSignedDocument(formData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', 'Signed Document Uploaded');
                getContractList();
                toggleUploadModal();
            } else {
                Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })

        setAppState(prevState => ({ ...prevState, isBusy: false }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                <div className="mt-2 mb-2">
                    <Row>
                        <Col><InputHoc name='signedDocument' label="Signed Document" inputType={InputTypes.FILEUPLOAD} control={control} multiple={false} ref={register({ required: true })} /></Col>
                    </Row>
                </div>
            }
            <button type="submit" className="hide" ref={ref}></button>
        </form>
    );
});

const NewProject = ({ setAppState, toggleProjectModal, getContractList, contractId }) => {
    const { register, handleSubmit, control, errors, watch } = useForm({});
    const watchStartDate = watch('startDate');
    const watchEndDate = watch('endDate');

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    const onSubmit = async (data) => {
        console.log("new project data", data);
        console.log("contarct id", contractId);
        const formData = new FormData();

        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("remark", data.remark);
        formData.append("contractId", contractId);

        if (!stringIsNullOrEmpty(data.startDate)) {
            formData.append("startDate", moment(data.startDate).format('YYYY-MM-DD'));
        }

        if (!stringIsNullOrEmpty(data.endDate)) {
            formData.append("endDate", moment(data.endDate).format('YYYY-MM-DD'));
        }

        let action = "";
        let dao = new ProjectDao();
        action = dao.createProject(formData, token);

        await action.then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Report.Success('Success', "Project Created Successful");
                getContractList();
                toggleProjectModal();
            } else {
                Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                <div className="mt-2 mb-2">
                    <Row>
                        <Col><InputHoc label='Project Title' name='title' inputType={InputTypes.INPUT} ref={register({ required: 'Project Title is required.' })} error={errors?.title?.message} /></Col>
                    </Row>
                    <Row>
                        <Col><InputHoc label='Project Description' name='description' inputType={InputTypes.INPUT} ref={register({ required: 'Project Description is required.' })} error={errors?.description?.message} /></Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputHoc
                                label='Start Date'
                                name='startDate'
                                inputType={InputTypes.DATEPICKER}
                                control={control}
                                ref={register()} error={errors?.startDate?.message}
                                startDate={watchStartDate}
                                endDate={watchEndDate}
                                maxDate={watchEndDate}
                            />
                        </Col>
                        <Col>
                            <InputHoc
                                label='End Date'
                                name='endDate'
                                inputType={InputTypes.DATEPICKER}
                                control={control}
                                ref={register()} error={errors?.endDate?.message}
                                startDate={watchStartDate}
                                endDate={watchEndDate}
                                minDate={watchStartDate}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col><InputHoc style={{ height: 'auto' }} label='Remark' name='remark' inputType={InputTypes.TEXTAREA} ref={register()} error={errors?.remark?.message} /></Col>
                    </Row>
                </div>
            }
            <button type="submit" className="btn btn-themed btn-min-width pull-right mt-2">Submit</button>
        </form>
    );
}

///<summary>
///Author: Sim
///</summary>
const Contract = props => {
    const [uploadModal, setUploadModal] = useState(false);
    const [newContractModal, setNewContractModal] = useState(false);
    const [projectModal, setProjectModal] = useState(false);
    const [contract, setContract] = useState(null);
    const [contractList, setContractList] = useState([]);
    const [contractId, setContractId] = useState(null);
    const [emptyContractQuotationList, setEmptyContractQuotationList] = useState([]);
    const setAppState = useSetRecoilState(appState);
    const submitRef = useRef();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    useEffect(() => {
        getEmptyContractQuotation();
        getContractList();
    }, [])

    const getEmptyContractQuotation = async () => {
        let dao = new ContractDao();
        await dao.getEmptyContractQuotation(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var quotations = [];
                data.map((item) => {
                    var options = {
                        label: item.quotationId,
                        value: item.id
                    }
                    quotations.push(options);
                });
                setEmptyContractQuotationList(quotations);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load empty contract quotation. Please try again later');
            }
        })
    }

    const getContractList = async () => {
        Block.Circle('#contractsTable');

        let dao = new ContractDao();
        await dao.getContractList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var contracts = [];
                console.log("Contract data", data);
                data.map((item) => {
                    contracts.push(item);
                });
                setContractList(contracts);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load contracts. Please try again later');
            }
        });

        Block.Remove('#contractsTable');
    }

    const setSelectedContract = (row) => {
        setContract(row);
    }

    const updateContract = async (row) => {
        var data = {
            newQuotationVersion: row.quotation.currentVersion.version,
            currentContractVersion: row.currentVersion.version,
            contractId: row.id
        };

        let dao = new ContractDao();
        await dao.updateContract(data, token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                Notiflix.Report.Success('Success', "Contract Updated");
                getContractList();
            } else {
                Notiflix.Report.Failure('Error', 'Failed to update contract version. Please try again later');
            }
        })
    }

    const createProject = async (row) => {
        toggleProjectModal();
        setContractId(row.id);
    }

    const _COLUMN = useMemo(() => [
        {
            Header: "Contract ID",
            Cell: ({ row }) => (
                <b>{row.original.contractId}</b>
            ),
        },
        {
            Header: "Quotation ID",
            accessor: "quotationId"
        },
        {
            Header: "Signed Document",
            Cell: ({ row }) => {
                if (!row.original.currentVersion.signedDocument) {
                    return <span className="text-red">Not signed yet.</span>
                }

                return (
                    <a href={`${_IMAGE_URL}/contract/signed_document/${row.original.currentVersion.signedDocument}`} target="_blank">
                        {row.original.currentVersion.signedDocument}
                    </a>)
                    ;
            },
        },
        {
            Header: "Action",
            Cell: ({ row }) => (<>
                <div
                    className="btn-round expand-theme btn-sm m-l-5"
                    data-for={`editBtn-${row.original.contractId}`}
                    data-tip="Upload Signed Document"
                    onClick={() => { toggleUploadModal(); setSelectedContract(row.original) }}
                >
                    <i className="fa fa-edit"></i>
                </div>
                {/* {row.original.quotation.signOff == 0 &&
                    <div className="btn-round expand-theme btn-sm m-l-5" title="Create Project"
                        onClick={() => {
                            Notiflix.Confirm.Show('Confirmation', 'Create project based on this contract?', 'Yes', 'No',
                                function () {
                                    createProject(row.original);
                                }
                            )
                        }}
                    ><i className="fa fa-file"></i></div>
                } */}
                <ReactTooltip id={`editBtn-${row.original.contractId}`} />
            </>
            ),
            disableSortBy: true,
            disableFilters: true,
            style: { overflow: "visible" }
        }
    ])

    const toggleUploadModal = () => { setUploadModal(!uploadModal); setContract(null); };
    const toggleNewContractModal = () => { getEmptyContractQuotation(); setNewContractModal(!newContractModal); };
    const toggleProjectModal = () => { setProjectModal(!projectModal) };

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const handleSubmit = () => {
        submitRef.current.click();
    }

    return (<>
        <ol className="breadcrumb float-xl-right">
            <li className='breadcrumb-item active'>Project Contract Management</li>
        </ol>
        <h1 className="page-header">Project Contract Management</h1>
        <button type="type" className="btn btn-sm btn-themed mb-2" onClick={toggleNewContractModal} ><i className="fa fa-plus mr-1"></i>Add New Contract</button>
        <Card id="contractsTable">
            <CardBody>
                <ReactTable columns={_COLUMN} data={contractList} />
            </CardBody>
        </Card>
        <Modal isOpen={uploadModal} toggle={toggleUploadModal} centered>
            <ModalHeader toggle={toggleUploadModal}>{"Upload Signed Document"}</ModalHeader>
            <ModalBody>
                <UploadContract
                    ref={submitRef}
                    setAppState={setAppState}
                    toggleUploadModal={toggleUploadModal}
                    contract={contract && contract}
                    getContractList={getContractList}
                />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-themed btn-min width" onClick={handleSubmit}>Upload</button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={newContractModal} toggle={toggleNewContractModal} centered>
            <ModalHeader toggle={toggleNewContractModal}>{"Add New Contract"}</ModalHeader>
            <ModalBody>
                <NewContract
                    ref={submitRef}
                    setAppState={setAppState}
                    toggleNewContractModal={toggleNewContractModal}
                    emptyContractQuotationList={emptyContractQuotationList && emptyContractQuotationList}
                    getContractList={getContractList}
                    getEmptyContractQuotation={getEmptyContractQuotation}
                />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-themed btn-min-width" onClick={handleSubmit}>Add</button>
            </ModalFooter>
        </Modal>
        <Modal isOpen={projectModal} toggleProjectModal={toggleProjectModal} centered>
            <ModalHeader toggleProjectModal={toggleProjectModal}>{"Add New Project"}</ModalHeader>
            <ModalBody>
                <NewProject
                    setAppState={setAppState}
                    toggleProjectModal={toggleProjectModal}
                    getContractList={getContractList}
                    contractId={contractId}
                />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-red" onClick={toggleProjectModal}>Cancel</button>
            </ModalFooter>
        </Modal>
    </>)
}

export default Contract;
