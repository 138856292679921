import React from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Phone = (props) => {
    return (
        <Controller
            as={<PhoneInput inputClass="form-control-md" />}
            name="phoneNumber"
            {...props}
        />
    );
};

Phone.defaultProps = {
    defaultValue: null,
    country: 'my',
}

export default Phone;