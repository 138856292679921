import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class ContractDao extends DataAccessObject {

    async getContractList(token) {
        return this.get(ApiUrl._API_CONTRACT_LIST, token);
    }

    async createNewContract(data, token) {
        return this.post(ApiUrl._API_CREATE_CONTRACT, data, token)
    }

    async getEmptyContractQuotation(token) {
        return this.get(ApiUrl._API_EMPTY_CONTRACT_QUOTATION_LIST, token);
    }

    async uploadSignedDocument(data, token) {
        return this.postForm(ApiUrl._API_UPLOAD_SIGNED_DOCUMENT, data, token);
    }

    async updateContract(data, token) {
        return this.post(ApiUrl._API_UPDATE_CONTRACT, data, token)
    }

    async getContractSites(token) {
        return this.get(ApiUrl._API_GET_CONTRACT_SITES, token);
    }

    async getContractBelongsToSite(id, token) {
        return this.get(`${ApiUrl._API_GET_SITE_CONTRACTS}/${id}`, token);
    }
}

export default ContractDao;
