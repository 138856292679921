import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

class UserDao extends DataAccessObject {

    async getUserList(token) {
        return this.get(ApiUrl._API_GET_USER, token);
    }
}

export default UserDao;
