import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const TextArea = forwardRef((props, ref) => {
    const {
        error,
        ...rest
    } = props;
    const [hasError, setHasError] = useState();

    const _inputErrors = classnames(
        'form-control',
        'form-control-lg',
        'm-b-5',
        { 'has-error': hasError }
    );

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setHasError(error);
    }, [error]);

    return <textarea ref={ref} {...rest} className={_inputErrors}></textarea>;
});

TextArea.defaultProps = {
    rows: 4,
    placeholder: ' '
}

export default TextArea;