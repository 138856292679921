import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { generateRandString } from 'util/Utility';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Radio = forwardRef((props, ref) => {
    const {
        options,
        name,
        className,
        defaultValue,
        inline,
        ...otherProps
    } = props;

    const radioClasses = classnames('radio radio-css m-b-10',
        className, { 'radio-inline': inline }
    );

    return <>
        {
            options.map((item, key) => {
                let id = `${name}-${generateRandString()}`;

                return (
                    <div key={key} className={radioClasses}>
                        <input ref={ref} id={id} name={name} value={item.value} defaultChecked={defaultValue == item.value} {...otherProps} />
                        <label htmlFor={id}>{item.label}</label>
                    </div>
                );
            })
        }
    </>;
});

Radio.defaultProps = {
    className: '',
    type: 'radio'
}

export default Radio;