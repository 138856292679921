import React from 'react';
import PropTypes from 'prop-types';
import { BtnTypes } from 'util/Constant';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const ButtonRound = ({ title, type, className, small, onClick }) => {
    const _buttons = {
        [BtnTypes.ADD]: { classes: "btn-round expand-green", title: 'Add', icon: 'fas fa-plus' },
        [BtnTypes.DELETE]: { classes: "btn-round expand-red", title: 'Delete', icon: 'fas fa-trash-alt' },
        [BtnTypes.VIEW]: { classes: "btn-round expand-theme", title: 'View', icon: 'fas fa-eye' },
        [BtnTypes.EDIT]: { classes: "btn-round expand-theme", title: 'Edit', icon: 'fas fa-pencil-alt' },
        [BtnTypes.MISC]: { classes: "btn-round expand-blue", title: 'Misc', icon: 'fas fa-shopping-cart' },
    }

    return (
        <div className={`${_buttons[type].classes} ${small && 'btn-sm'} ${className}`} title={title ?? _buttons[type].title} onClick={onClick}>
            <i className={_buttons[type].icon}></i>
        </div>
    );
}

ButtonRound.defaultProps = {
    type: BtnTypes.ADD,
    small: false,
    onClick: () => { return null }
}

ButtonRound.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string.isRequired
}

export default ButtonRound;