import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardHeader, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Notiflix from "notiflix";
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { Block, Report } from 'notiflix';
import moment from 'moment';
import CustomInput from "components/form/CustomInput";
import CustomSelect from "components/form/CustomSelect";
import InputHoc from "components/form/InputHoc.js";
import NavigationButton from 'components/buttons/NavigationButton';

import { InputTypes, SelectField, WebUrl, ApiKey } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import { tryCatch } from 'util/Utility';

import ProjectDao from "data/ProjectDao";
import QuotationDao from "data/QuotationDao";
import UserDao from 'data/UserDao';

let _PRIORITY_OPTIONS = [
    { label: 'Low', value: 0 },
    { label: 'Medium', value: 1 },
    { label: 'High', value: 2 },
    { label: 'Urgent', value: 3 },
];

let _STATUS_OPTIONS = [
    { label: 'Pending', value: 0 },
    { label: 'In Progress', value: 1 },
    { label: 'Completed', value: 2 },
    { label: 'All Tasks Complete', value: 3 },
];

/// <summary>
/// Author: Christopher Chan
/// </summary>
const QuotationItem = ({ item, selected, itemSelectionHandler }) => (
    <div className="project-quotation-items-check" onClick={() => itemSelectionHandler(item.id, selected ? 'remove' : 'add')}>
        <Row>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
                <h3>{item.name}</h3>
            </Col>
            <Col xs={2} className="flex-center">
                {
                    selected ? <i class="las la-check-circle icon-selected"></i> : <i class="las la-circle"></i>
                }
            </Col>
        </Row>
    </div>
);

///<summary>
///Author: Christopher Chan
///</summary>
export default () => {
    const state = useLocation()?.state;
    const [quotation, setQuotation] = useState(state != undefined ? state?.quotation : {});
    const [quotationList, setQuotationList] = useState([]);
    const [quotationOptions, setQuotationOptions] = useState([]);
    const [quotationItems, setQuotationItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [selectedQuotationItems, setSelectedQuotationItems] = useState([]);
    const { register, control, handleSubmit, setValue, errors, watch, reset } = useForm({
        status: 0,
        priority: 0,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: `items`
    });
    const watchQuotationSelection = watch('quotationId');

    const history = useHistory();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getQuotationList = async () => {
        let dao = new QuotationDao();
        await dao.getQuotationList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var quotations = [];

                data.map((item) => {
                    if (item.signOff == 0) {
                        var quo = {
                            value: item.id,
                            label: `${item.quotationId} ${item?.contract ? `(${item?.contract?.contractId})` : ''}`
                        }
                        quotations.push(quo);
                    }
                });

                setQuotationList(data);
                setQuotationOptions(quotations);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load quotations. Please try again later');
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getUserList = async () => {
        let dao = new UserDao();
        await dao.getUserList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];

                setUsers(data.map(user => ({
                    label: `${user.fullName} (${user.employeeNumber})`,
                    value: user.userId
                })));
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load quotations. Please try again later');
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = (data) => {
        tryCatch(async () => {
            let newData = {
                ...data,
                startDate: moment(data.startDate).format('YYYY-MM-DD'),
                endDate: moment(data.endDate).format('YYYY-MM-DD'),
            };
            let dao = new ProjectDao();

            await dao.createProject(newData, token).then(responseJson => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    Notiflix.Report.Success('Success', "Project Created Successful", "Okay", () => history.goBack());
                } else {
                    Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
                }
            })
        });
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const itemSelectionHandler = (id, type) => {
        let newArr = [...selectedQuotationItems];

        if (type === 'add') {
            let item = quotationItems.find(x => x.id === id);
            newArr.push(id);

            console.log(item);

            append({
                elevatorModelId: item.elevatorModelId,
                elevatorModelSuffix: item.suffix,
                quotationItemId: id,
                itemTitle: item.name,
                siteId: quotation.site.id,
                userId: quotation.site.customer.id,
            });
        }
        else {
            var selectedIndex = selectedQuotationItems.indexOf(id);
            newArr.splice(selectedIndex, 1);
            remove(selectedIndex);
        }

        setSelectedQuotationItems(newArr);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        getQuotationList();
        getUserList();
    }, []);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (watchQuotationSelection) {
            let quotation = quotationList.find(x => x.id === watchQuotationSelection);

            setQuotation(quotation);
            setValue('quotationId', quotation?.id);
            setQuotationItems(quotation?.latestVersion?.items);
            setCustomerDetails(quotation?.site?.customer);
        }
    }, [watchQuotationSelection]);

    return <>
        <form id="enquiryForm" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="quotationId" />

            <h1 className="page-header">
                <NavigationButton />
                Add Project
            </h1>
            <Row>
                <Col>
                    <Card className="mb-2">
                        <CardHeader>1. Select Quotation</CardHeader>
                        <CardBody>
                            <CustomSelect
                                control={control}
                                name="quotationId"
                                label="Quotations"
                                placeholder="Quotations *"
                                options={quotationOptions}
                                rules={{
                                    required: true,
                                }}
                                error={errors?.quotationId?.message}
                                errors={errors?.quotationId?.message}
                            />
                        </CardBody>
                    </Card>
                    <Card className="mb-2">
                        <CardHeader>2. Project Details</CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <CustomInput
                                        {...register("title", {
                                            required: "title is required",
                                        })}
                                        name="title"
                                        label="Project Title *"                                      
                                        showLabel
                                    />                                   
                                </Col>
                                <Col>
                                    <CustomInput
                                        {...register("description", {
                                            required: "Project Description is required",
                                        })}
                                        name="description"
                                        label="Project Description *"                                      
                                        showLabel
                                    />        
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <CustomSelect
                                        control={control}
                                        name="status"
                                        label="Status"
                                        placeholder="Status *"
                                        options={_STATUS_OPTIONS}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        control={control}
                                        name="priority"
                                        label="Priority"
                                        placeholder="Priority *"
                                        options={_PRIORITY_OPTIONS}
                                        rules={{
                                            required: true,
                                        }}
                                    />    
                                </Col>
                                <Col>
                                    <CustomSelect
                                        control={control}
                                        name="pic"
                                        label="PIC"
                                        placeholder="PIC *"
                                        options={users}
                                        rules={{
                                            required: true,
                                        }}
                                    />    
                                </Col>
                                <Col>
                                    <InputHoc name="remarks" label="Remarks (optional)" />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col xs={4}>
                            <Card>
                                <CardHeader>3. Select item(s)</CardHeader>
                                <CardBody className="scrollable-container" style={{ height: '65vh' }}>
                                    {
                                        quotationItems.map(item => {
                                            return <QuotationItem key={item.id} item={item} selected={selectedQuotationItems.includes(item.id)} itemSelectionHandler={itemSelectionHandler} />
                                        })
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardHeader>4. Enter Details</CardHeader>
                                <CardBody className="scrollable-container" style={{ height: '65vh' }}>
                                    {
                                        fields.map((item, index) => {
                                            return <>
                                                {
                                                    index !== 0 && <hr />
                                                }
                                                <Row key={item.id}>
                                                    <Col>
                                                        <Row>
                                                            <Col>
                                                                <b>{item?.itemTitle}</b>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <InputHoc
                                                                    name={`items[${index}].typeOfLift`}
                                                                    label="Type of Lift"
                                                                    inputType={InputTypes.SELECT}
                                                                    control={control}
                                                                    options={SelectField.TYPE_OF_LIFT_OPTIONS}
                                                                    error={errors?.items?.[index]?.typeOfLift}
                                                                    defaultValue={item?.typeOfLift}
                                                                    rules={{ required: 'required' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputHoc
                                                                    label='Start Date'
                                                                    name={`items[${index}].startDate`}
                                                                    inputType={InputTypes.DATEPICKER}
                                                                    control={control}
                                                                    error={errors?.items?.[index]?.startDate}
                                                                    rules={{ required: 'required' }}
                                                                    defaultValue={item?.startDate}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputHoc
                                                                    label='End Date'
                                                                    name={`items[${index}].endDate`}
                                                                    inputType={InputTypes.DATEPICKER}
                                                                    control={control}
                                                                    error={errors?.items?.[index]?.endDate}
                                                                    rules={{ required: 'required' }}
                                                                    defaultValue={item?.endDate}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <InputHoc name={`items[${index}].remarks`} label="Remarks (optional)" ref={register()} defaultValue={item?.remarks} />
                                                            </Col>
                                                            <input className="hide" name={`items[${index}].elevatorModelId`} ref={register} defaultValue={item?.elevatorModelId} />
                                                            <input className="hide" name={`items[${index}].quotationItemId`} ref={register} defaultValue={item?.quotationItemId} />
                                                            <input className="hide" name={`items[${index}].elevatorModelSuffix`} ref={register} defaultValue={item?.elevatorModelSuffix} />
                                                            <input className="hide" name={`items[${index}].userId`} ref={register} defaultValue={item?.userId} />
                                                            <input className="hide" name={`items[${index}].siteId`} ref={register} defaultValue={item?.siteId} />
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        })
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xs={2}>
                    <button type="submit" className="btn btn-themed btn-block">Save</button>
                    <hr />
                    {
                        customerDetails && <>
                            <h5>Customer Details</h5>
                            <ul>
                                <li>{customerDetails?.fullName}</li>
                                <li>{customerDetails?.companyName}</li>
                                <li>{customerDetails?.ssmNumber}</li>
                                <li>{customerDetails?.emails[0]?.email}</li>
                                <li>{customerDetails?.phones[0]?.number}</li>
                            </ul>
                        </>
                    }
                </Col>
            </Row>
        </form>
    </>;
}