import React, { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import ReactTable from 'components/react-table/ReactTable';
import { Card, CardBody, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import Notiflix from "notiflix";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Block, Confirm, Loading } from 'notiflix';

import InputHoc from "components/form/InputHoc.js";
import { InputTypes, SelectField, ApiKey, Colors } from "util/Constant";
import { Config, ConfigEnum } from 'util/Config';
import ProjectDao from "data/ProjectDao.js";
import UserDao from "data/UserDao.js";
import NavigationButton from 'components/buttons/NavigationButton';
import ProjectTaskGroup from "./ProjectTaskGroup";

let _PRIORITY_OPTIONS = [
    { label: 'Low', value: 0 },
    { label: 'Medium', value: 1 },
    { label: 'High', value: 2 },
    { label: 'Urgent', value: 3 },
];

/// <summary>
/// Author: Christopher Chan
/// </summary>
const Task = forwardRef(({ task, assigneesList, getProjectTaskById, toggleModal }, ref) => {
    // might need functions to edit name, description, based on user role (superadmin only can edit)
    // in case special cases certain projects might need to change name/description

    const { register, handleSubmit, control, errors, watch, reset } = useForm({});
    const location = useLocation();
    const watchStatus = watch('status');

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const onSubmit = async (data) => {
        let assigneeArr = data?.assignees.map(item => { return item.value; });
        let newData = new FormData();

        newData.append("id", task?.id);
        newData.append("status", parseInt(data?.status));
        newData.append("priority", parseInt(data?.priority));
        newData.append("assignees[]", assigneeArr);

        newData.append("remark", data?.remark);

        if (data?.startDate !== undefined) {
            newData.append("startDate", moment(data?.startDate).format('YYYY-MM-DD'));
        }

        if (data?.endDate !== undefined) {
            newData.append("endDate", moment(data?.endDate).format('YYYY-MM-DD'));
        }

        if (data?.attachments && data?.attachments?.length > 0) {
            data.attachments.forEach(image => {
                newData.append("attachments[]", image);
            })
        }

        let dao = new ProjectDao();
        await dao.updateTask(newData, token).then(responseJson => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                Notiflix.Notify.Success('Successfully updated project task!');
                getProjectTaskById(location.state.id);
                toggleModal();
            } else {
                Notiflix.Report.Failure('Error', 'Operation Failed. ' + responseJson[ApiKey._API_MESSAGE_KEY]);
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (task) {
            let defaultValues = {
                ...task,
                startDate: task?.startDate ? new Date(task?.startDate) : new Date(),
                endDate: task?.endDate ? new Date(task?.endDate) : new Date(),
                status: String(task?.status),
                priority: String(task?.priority),
            }

            reset(defaultValues);
        }
    }, [task])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <strong>Task Details</strong>
            <Row>
                <Col xl="6">
                    <InputHoc
                        label='Status'
                        isMulti={false}
                        name='status'
                        inputType={InputTypes.SELECT}
                        control={control}
                        options={SelectField.STATUS_OPTIONS}
                        rules={{ required: true }}
                        error={errors?.status}
                    />
                </Col>
                <Col xl="6">
                    <InputHoc
                        formGroupClass={watchStatus === 1 || task?.startDate !== null ? 'show' : 'hide'}
                        label='Start Date'
                        name='startDate'
                        inputType={InputTypes.DATEPICKER}
                        control={control}
                        rules={{ required: watchStatus === 1 || task?.startDate !== null }}
                        error={errors?.startDate}
                    />
                    <InputHoc
                        formGroupClass={watchStatus === 2 || task?.endDate !== null ? 'show' : 'hide'}
                        label='End Date'
                        name='endDate'
                        inputType={InputTypes.DATEPICKER}
                        control={control}
                        rules={{ required: watchStatus === 2 || task?.endDate !== null }}
                        error={errors?.startDate}
                        startDate={new Date(task?.startDate)}
                        minDate={new Date(task?.startDate)}
                    />
                </Col>
            </Row>
            <InputHoc
                name='priority'
                label='Priority'
                inputType={InputTypes.SELECT}
                control={control}
                options={_PRIORITY_OPTIONS}
            />
            <InputHoc
                name='assignees'
                label='Assignees'
                inputType={InputTypes.SELECT}
                control={control}
                options={assigneesList}
                rules={{ required: true }}
                error={errors?.assignees}
                isMulti={true}
            />
            <InputHoc
                style={{ height: 'auto' }}
                label='Remark'
                name='remark'
                inputType={InputTypes.TEXTAREA}
                ref={register}
                error={errors?.remark?.message}
            />
            <InputHoc
                name="attachments"
                label="Attachments"
                inputType={InputTypes.ATTACHMENT}
                control={control}
                singleImage={false}
            />
            <button ref={ref} type="submit" style={{ display: 'none' }}>Save</button>
        </form>
    );
});

///<summary>
///Author: Chris
///</summary>
export default () => {
    const [projectTaskList, setProjectTaskList] = useState([]);
    const [assigneesList, setAssigneesList] = useState([]);
    const [collapseAll, setCollapseAll] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [task, setTask] = useState(null);
    const formRef = useRef();
    const location = useLocation();

    let configInstance = Config.getInstance();
    var token = configInstance.getValue(ConfigEnum._TOKEN);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getProjectTaskById = async (projectId) => {
        Block.Circle('#projectTasksContainer');

        let dao = new ProjectDao();
        await dao.getProjectTaskById(projectId, token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];
                var projectTasksObj = [];

                Object.keys(data).map(key => {
                    projectTasksObj.push({
                        name: key,
                        pendingCount: data[key].reduce((total, item) => (item.status === 0 ? ++total : total), 0),
                        inProgressCount: data[key].reduce((total, item) => (item.status === 1 ? ++total : total), 0),
                        completedCount: data[key].reduce((total, item) => (item.status === 2 ? ++total : total), 0),
                        tasks: data[key],
                    });
                });

                setProjectTaskList(projectTasksObj);
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load project tasks. Please try again later');
            }

            Block.Remove('#projectTasksContainer');
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const getUserList = async () => {
        let dao = new UserDao();
        await dao.getUserList(token).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                var data = response[ApiKey._API_DATA_KEY];

                setAssigneesList(data.map((item) => ({ label: item.firstName + " " + item.lastName + " (" + item.employeeNumber + ")", value: item.id })));
            } else {
                Notiflix.Report.Failure('Error', 'Failed to load users. Please try again later');
            }
        })
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const removeTask = (taskId) => {
        Confirm.Show(
            'Hold on!',
            'Are you sure you would like to mark this task as not applicable?',
            'Yes',
            'No',
            async () => {
                Loading.Init({
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    svgColor: Colors.THEME,
                    messageColor: Colors.WHITE,
                });

                Loading.Circle();

                let dao = new ProjectDao();
                await dao.removeProjectTaskById({ taskId }, token).then((response) => {
                    Loading.Remove();

                    if (response[ApiKey._API_SUCCESS_KEY]) {
                        getProjectTaskById(location.state.id);
                    } else {
                        Notiflix.Report.Failure('Error', 'Failed to remove project task. Please try again later');
                    }
                })
            }
        )
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const toggleModal = () => {
        setModalVisible(!modalVisible);

        if (modalVisible) {
            setTask(null);
        }
    };

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const editTask = (groupIndex, taskIndex) => {
        let task = projectTaskList[groupIndex]?.tasks[taskIndex];
        setTask(task);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const formHandler = () => {
        formRef.current.click();
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        if (task) {
            toggleModal();
        }
    }, [task]);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        getProjectTaskById(location.state.id);
        getUserList();
    }, []);

    return <>
        <div className="float-xl-right">
            <button type="type" className="btn btn-sm btn-themed mb-2" onClick={() => setCollapseAll(!collapseAll)}>
                {collapseAll ? 'Collapse All' : 'Expand All'}
            </button>
        </div>
        <h1 className="page-header">
            <NavigationButton />
            Project Tasks
        </h1>
        <div id="projectTasksContainer">
            {
                projectTaskList.map((item, index) => (
                    <ProjectTaskGroup key={item.name} index={index} item={item} collapseAll={collapseAll} removeTask={removeTask} editTask={editTask} />
                ))
            }
        </div>
        <Modal isOpen={modalVisible} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Update Task - {task?.task?.name}</ModalHeader>
            <ModalBody>
                <Task
                    ref={formRef}
                    task={task}
                    getProjectTaskById={getProjectTaskById}
                    toggleModal={toggleModal}
                    assigneesList={assigneesList}
                />
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-themed btn-min-width" onClick={formHandler}>Save</button>
            </ModalFooter>
        </Modal>
    </>
}
