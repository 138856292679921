import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';

/// <summary>
/// Author: Chris
/// </summary>
export default ({ pageOptions, setPageOptions, pageCount, canNextPage, canPreviousPage, previousPage, nextPage, gotoPage }) => {
    const [pages, setPages] = useState(1);

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        const { totalCount, pageSize } = pageOptions;
        let delta = Math.ceil(totalCount / pageSize);
        setPages(delta);
    }, [pageOptions]);

    return <Pagination
        count={pages}
        page={pageOptions.page}
        onChange={(e, val) => { setPageOptions(prev => ({ ...prev, page: val })); gotoPage(val - 1) }}
        size='small'
        siblingCount={2}
    />

    // return (
    //     <div className="table-page-buttons">
    //         <div className="mr-4">
    //             Page {pageOptions.page} of {pages}
    //         </div>

    //         <div className="btn btn-round btn-default btn-sm mr-2" onClick={() => {
    //             setPageOptions({
    //                 ...pageOptions,
    //                 page: 1,
    //                 initial: false,
    //             })
    //         }}>
    //             <i className="fas fa-angle-double-left"></i>
    //         </div>
    //         <div className="btn btn-round btn-default btn-sm mr-2" onClick={() => {
    //             if (pageOptions.page !== 1) {
    //                 setPageOptions({
    //                     ...pageOptions,
    //                     page: pageOptions.page - 1,
    //                     initial: false,
    //                 })
    //             }

    //             return;
    //         }}>
    //             <i className="fas fa-angle-left"></i>
    //         </div>
    //         <div className="btn btn-round btn-default btn-sm mr-2" onClick={() => {
    //             if (pages > 1 && pageOptions.page !== pages) {
    //                 setPageOptions({
    //                     ...pageOptions,
    //                     page: pageOptions.page + 1,
    //                     initial: false,
    //                 })
    //             }

    //             return;
    //         }}>
    //             <i className="fas fa-angle-right"></i>
    //         </div>
    //         <div className="btn btn-round btn-default btn-sm" onClick={() => {
    //             setPageOptions({
    //                 ...pageOptions,
    //                 page: pages,
    //                 initial: false,
    //             })
    //         }}>
    //             <i className="fas fa-angle-double-right"></i>
    //         </div>
    //     </div>
    // );
}