import React, { useState, useEffect } from "react";
import { Card, CardBody, Collapse } from 'reactstrap';

import ProjectTask from './ProjectTask';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export default ({ item, index, editTask, removeTask, collapseAll = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    const handleCollapsible = () => {
        setIsOpen(!isOpen);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    useEffect(() => {
        setIsOpen(collapseAll);
    }, [collapseAll]);

    return <>
        <div className="d-flex flex-row align-items-center mb-3">
            <Card className="flex-fill task-group-card" onClick={handleCollapsible}>
                <CardBody className="p-t-10 p-b-10">
                    <div className="task-group-row">
                        <i className={isOpen ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                        <div className="task-group-name">
                            {item?.name}
                        </div>
                        <div className="d-flex flex-row align-items-center" style={{ flex: 1, justifyContent: 'flex-end' }}>
                            <div className="label label-pink">{item?.tasks?.length} tasks</div>
                            <div className="badge badge-secondary">{item?.pendingCount} pending</div>
                            <div className="badge badge-indigo">{item?.inProgressCount} in progress</div>
                            <div className="badge badge-success">{item?.completedCount} completed</div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>

        <Collapse className="task-list-container" isOpen={isOpen}>
            <div className="task-row">
                <i className="fas fa-level-up-alt"></i>
                <Card className="flex-fill task-card">
                    <CardBody className="task-details-row p-t-10 p-b-10">
                        <div className="task-name">
                            <div className="badge badge-info">Name</div>
                        </div>
                        <div className="task-desc">
                            <div className="badge badge-info">Description</div>
                        </div>
                        <div className="task-duration">
                            <div className="badge badge-info">Due On</div>
                        </div>
                        <div className="task-duration">
                            <div className="badge badge-info">Assignees</div>
                        </div>
                        <div style={{ width: '80px', marginRight: '10px' }}>
                            <div className="badge badge-info">Status</div>
                        </div>
                        <div style={{ width: '80px' }}>
                            <div className="badge badge-info">Priority</div>
                        </div>
                    </CardBody>
                </Card>
                <button type="button" className="btn expand-theme btn-round ml-3">
                    <i class="far fa-edit"></i>
                </button>
                <button type="button" className="btn expand-theme btn-round ml-2 ">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            {
                item?.tasks?.map((task, taskIndex) => (
                    <ProjectTask key={task.name} groupIndex={index} taskIndex={taskIndex} task={task} editTask={editTask} removeTask={removeTask} />
                ))
            }
        </Collapse>
    </>
}
