import { stringIsNullOrEmpty } from './Utility';

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class ConfigEnum {
    static _TOKEN = 'token';
    static _USER = 'user';
    static _PERMISSIONS = 'user_permissions';
}

var _config = {};
_config[ConfigEnum._TOKEN] = '';
_config[ConfigEnum._USER] = null;
_config[ConfigEnum._PERMISSIONS] = null;

/// <summary>
/// Author: Christopher Chan
/// </summary>
export class Config {
    static _instance = null;

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    static getInstance() {
        if (stringIsNullOrEmpty(Config._instance)) {
            Config._instance = new Config();
            Config._instance.readData();
        }

        return Config._instance;
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    getValue(key) {
        return _config[key];
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    getJson(key) {
        return JSON.parse(_config[key]);
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    getCustomJsonObj(configKey, selectedKeys) {
        let configObj = _config[configKey];

        let customObj = {};
        selectedKeys.map((key) => {
            customObj[key] = configObj[key];
        });

        return customObj;
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    setData(key, value) {
        if (_config.hasOwnProperty(key)) {
            _config[key] = value;
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    saveData() {
        for (const [key, value] of Object.entries(_config)) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    /// <summary>
    /// Author: Christopher Chan
    /// </summary>
    readData() {
        for (const [key, value] of Object.entries(_config)) {
            const storageValue = localStorage.getItem(key);
            if (!stringIsNullOrEmpty(storageValue)) {
                _config[key] = JSON.parse(storageValue);
            }
        }
    }
}
