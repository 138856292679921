import React from 'react';
import Select from 'react-select';
import { Colors } from 'util/Constant';

/// <summary>
/// Author: Chris
/// </summary>
export default ({ pageSize, setPageSize, pageOptions, setPageOptions }) => {
    let options = [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '250', value: 250 },
        { label: '500', value: 500 },
    ];

    const _selectStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            minHeight: 'none',
            height: 35,
        }),
        option: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? Colors.THEME
                    : isFocused
                        ? Colors.OFF_THEME
                        : null,
            ':active': {
                ...provided[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? Colors.THEME : Colors.OFF_THEME),
            },
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            padding: '0px !important',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px',
            paddingLeft: '5px',
            paddingRight: '28px',
        })
    };

    return (
        <div className="table-entries">
            <div>Rows per page</div>
            <div className="entries-select">
                <Select
                    onChange={
                        (e) => {
                            setPageSize(Number(e.value));
                            setPageOptions({
                                ...pageOptions,
                                page: 1,
                                pageSize: Number(e.value),
                                initial: false,
                            });
                        }
                    }
                    value={options?.filter(x => x.value === pageSize)}
                    styles={_selectStyles}
                    options={options}
                    isSearchable={false}
                    menuPlacement='auto'
                />
            </div>
        </div>
    );
}