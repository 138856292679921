import React from 'react';
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap';
import { PageSettings } from './../../config/page-settings.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SidebarNav from '../sidebar/sidebar-nav';
import { Link } from 'react-router-dom';
import { SidebarType } from '../../util/Constant';

class SidebarRight extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({ pageTwoSidebar, activeSidebar }) => (
                    <React.Fragment>
                        {
                            activeSidebar == SidebarType._ADMIN &&
                            <>
                                <React.Fragment>
                                    <div id="sidebar-right" className="sidebar sidebar-right">
                                        <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                                            <SidebarNav userType="Admin" />
                                        </PerfectScrollbar>
                                    </div>
                                    <div className="sidebar-bg sidebar-right"></div>
                                </React.Fragment>
                            </>
                        }
                    </React.Fragment>
                )}
            </PageSettings.Consumer>
        )
    }
}


export default SidebarRight;
