import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InputTypes } from 'util/Constant';
import _ from 'lodash/object';

import { Select, Input, Textarea, Radio, Phone, Attachment, Datepicker, Checkbox, FileUpload } from 'components/form';

/// <summary>
/// Author: Christopher Chan
/// </summary>
const InputHoc = forwardRef((props, ref) => {
    const {
        formGroup,
        inline,
        label,
        error,
        smallnote,
        inputType,
        labelClasses,
        formGroupClass,
        postfix,
        showErrorMsg,
        ...otherProps
    } = props;

    const InputComponents = {
        [InputTypes.INPUT]: Input,
        [InputTypes.SELECT]: Select,
        [InputTypes.RADIO]: Radio,
        [InputTypes.PHONE]: Phone,
        [InputTypes.TEXTAREA]: Textarea,
        [InputTypes.ATTACHMENT]: Attachment,
        [InputTypes.DATEPICKER]: Datepicker,
        [InputTypes.CHECKBOX]: Checkbox,
        [InputTypes.FILEUPLOAD]: FileUpload,
    }

    const MaterialUI = [InputTypes.INPUT, InputTypes.SELECT, InputTypes.TEXTAREA, InputTypes.DATEPICKER];

    let wrapperClasses = classnames(
        {
            'form-group': formGroup,
            'd-flex': inline,
            [formGroupClass]: formGroupClass,
            'has-error': error
        },
    );

    if (typeof InputComponents[inputType] !== 'undefined') {
        return (
            <div className={wrapperClasses}>
                {postfix ?
                    <div class="d-flex form-group-wrapper">{React.createElement(InputComponents[inputType], { error: error, ref, ...otherProps })}
                        <span className="input-postfix">{postfix}</span>
                        {label && <label className={classnames({ [labelClasses]: labelClasses })}>{label}</label>}
                    </div> :
                    !MaterialUI.includes(inputType) ? (<>
                        {label && <label className={classnames({ [labelClasses]: labelClasses })}>{label}</label>}
                        {React.createElement(InputComponents[inputType], { error: error, ref, ...otherProps })}
                    </>)
                        : (<>
                            {React.createElement(InputComponents[inputType], { error: error, ref, ...otherProps })}
                            {label && <label className={classnames({ [labelClasses]: labelClasses })}>{label}</label>}</>)
                }
                {smallnote && <span className="input-smallnote">{smallnote}</span>}
                {smallnote && error && <br />}
                {error && showErrorMsg && <span className="input-error">{error}</span>}
            </div>
        );
    }

    return null;
});

InputHoc.defaultProps = {
    formGroup: true,
    inline: false,
    inputType: InputTypes.INPUT,
    formGroupClass: ''
}

InputHoc.propTypes = {
    formGroup: PropTypes.bool,
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    smallnote: PropTypes.string,
    inputType: PropTypes.oneOf(_.values(InputTypes)),
    labelClasses: PropTypes.string,
    formGroupClass: PropTypes.string,
}

export default InputHoc;