import React, { useState, useEffect, forwardRef } from "react";
import classnames from "classnames";
import { FaLock } from "react-icons/fa";

/// <summary>
/// Author: Christopher Chan
/// </summary>
const CustomInput = forwardRef((props, ref) => {
  const {
    readOnly,
    icon,
    label,
    placeholder,
    showLabel,
    isEditable = true,
    style,
    error,
    ...rest
  } = props;

  return (
    <>
      {showLabel && <label className="custom-input-label">{label}</label>}
      <div
        className={`input-container ${error ? "has-error" : ""} ${
          readOnly ? "disabled" : ""
        }`}
        style={style}
      >
        {icon && (
          <div className={`icon-container ${readOnly ? "disabled" : ""}`}>
            {icon}
          </div>
        )}
        <input
          ref={ref}
          {...rest}
          placeholder={placeholder ?? label}
          className={`custom-form-control ${readOnly ? "disabled" : ""}`}
          readOnly={readOnly || !isEditable}
        />

        {/* {
                readOnly && (
                    <div className="disabled-icon">
                        <FaLock style={{ color: '#999' }} size={16} />
                    </div>
                )
            } */}
      </div>
      <div>{error && <span style={{ color: "red" }}>{error}</span>}</div>
    </>
  );
});

export default CustomInput;
