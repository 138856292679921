import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

/**
 * Author : Sim
 * DataAccessObject's fetch will return the promise.
 */
class QuotationDao extends DataAccessObject {

    async getQuotationList(token) {
        return this.get(ApiUrl._API_QUOTATION_LIST, token);
    }

    async getQuotationItems(id, token) {
        return this.get(`${ApiUrl._API_QUOTATION_ITEMS}/${id}`, token);
    }
}

export default QuotationDao;
