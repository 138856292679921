import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from './../util/Constant';

import Login from 'pages/common/LoginPage';
import Dashboard from 'pages/dashboard/Dashboard';
import Contract from 'pages/contract/Contract';
import Project from 'pages/project/Project';
import ProjectTasks from 'pages/project/ProjectTasks';
import CreateProject from 'pages/project/CreateProject';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={WebUrl._LOGIN} />,
        authRequired: false,
    },
    {
        path: WebUrl._DASHBOARD,
        title: 'Dashboard',
        component: () => <Dashboard />,
        authRequired: true,
    },
    {
        path: WebUrl._LOGIN,
        title: 'Login',
        component: () => <Login />,
        authRequired: false,
    },
    {
        path: WebUrl._CONTRACT,
        title: 'Contract',
        component: () => <Contract />,
        authRequired: true,
    },
    {
        path: WebUrl._PROJECT,
        title: 'Project',
        component: () => <Project />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROJECT_TASKS,
        title: 'Project Tasks',
        component: () => <ProjectTasks />,
        authRequired: true,
        exact: true,
    },
    {
        path: WebUrl._PROJECT_CREATE,
        title: 'Add Project',
        component: () => <CreateProject />,
        authRequired: true,
        exact: true,
    },
];


export default routes;