import { ApiUrl } from 'util/Constant';
import DataAccessObject from './DataAccessObject';

class ProjectDao extends DataAccessObject {

    async getProjectList(token) {
        return this.get(ApiUrl._API_GET_PROJECT, token);
    }

    async createProject(data, token) {
        return this.post(ApiUrl._API_CREATE_PROJECT, data, token);
    }

    async updateProject(data, token) {
        return this.post(ApiUrl._API_UPDATE_PROJECT, data, token);
    }

    async getProjectTaskById(projectId, token) {
        return this.get(`${ApiUrl._API_GET_PROJECT_TASKS_BY_ID}/${projectId}`, token);
    }

    async removeProjectTaskById(data, token) {
        return this.post(ApiUrl._API_REMOVE_PROJECT_TASK, data, token);
    }

    async updateTask(data, token) {
        return this.postForm(ApiUrl._API_UPDATE_TASK, data, token);
    }

    async getServiceAgentsList(token) {
        return this.get(ApiUrl._API_SERVICE_AGENT_LIST, token);
    }

    async completeProject(data, token) {
        return this.post(ApiUrl._API_PROJECT_COMPLETE, data, token);
    }
}

export default ProjectDao;
